import './bootstrap';

window.onDownloadButtonClick = function (isTiktok) {
    if (isTiktok === 'tiktok') {
        alert('Please long press the button and click "Open link"')
    }

    try {
        window.fathom.trackGoal('2C4BB4HB', 0);
        gtag('event', 'conversion', {'send_to': 'AW-11285537432/JdoCCJ6i9MsYEJjFroUq'});
    } catch (e) {

    }
};


